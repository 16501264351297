// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createBrowserRouter } from 'react-router-dom'

// pages
import { Domu, Sluzby, Eshop, Pribeh, Kontakt } from './pages'

import Error from './pages/Error'

// import ScrollToTop from '../components/ScrollToTop'
// import EnlargeNavbar from '../components/EnlargingNavbar'

// navbar
import Layout from './pages/Layout'

const ReactRouterSetup = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: (
      <>
        <Layout />
        <Error />
      </>
    ),

    children: [
      {
        index: true,
        element: <Domu />,
      },
      {
        path: 'pribeh',
        element: <Pribeh />,
      },
      {
        path: 'sluzby',
        element: <Sluzby />,
        // errorElement: <SinglePageError />,
      },
      {
        path: 'kontakt',
        element: <Kontakt />,
      },
      {
        path: 'eshop',
        element: <Eshop />,
      },
    ],
  },
])
// <Router>
//   <ScrollToTop>
//     <Switch>
//       <Route exact path="/">
//         <Navbar />

//         <Domu />
//         <EnlargeNavbar />
//       </Route>
//       <Route path="/sluzby">
//         <Navbar />
//         <EnlargeNavbar />
//         <Sluzby />
//       </Route>

//       <Route path="/eshop">
//         {/* <Navbar /> */}
//         <Eshop />
//       </Route>
//       <Route path="/Pribeh">
//         <Navbar />
//         <EnlargeNavbar />

//         <Pribeh />
//       </Route>
//       <Route path="/kontakt">
//         <Navbar />
//         <EnlargeNavbar />

//         <Kontakt />
//       </Route>
//       <Route path="/person/:id" children={<Person />}>
//         {' '}
//         <Navbar />
//       </Route>
//       <Route path="*">
//         <Navbar />

//         <Error />
//       </Route>
//     </Switch>
//   </ScrollToTop>
// </Router>

export default ReactRouterSetup
