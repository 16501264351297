import React from 'react'
import styled from 'styled-components'
import { Reveal, Timeline, Tween } from 'react-gsap'

const ParallaxStyled = styled.div`
  /* The Transformation */
  // img:hover {
  //   filter: brightness(100%);
  //   transform: scale(1.1);
  //   transition: transform 5s, filter 3s ease-in-out;
  // }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .section {
    height: 100vh;

    position: relative;
    background-color: black;
    scroll-snap-align: start;
    h1 {
      position: absolute;
      left: 15%;
      top: 15%;
      font-size: 2.5em;
      color: white;
      text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
        0px -5px 35px rgba(255, 255, 255, 0.3);
    }
  }
`

const Eshop = () => (
  <div style={{ overflow: 'hidden' }}>
    <ParallaxStyled style={{ 'background-color': 'black' }}>
      <div className='section'>
        <Timeline
          target={
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'relative',
                opacity: '0',
                background: "url('./imgs/prowines.jpg') no-repeat",
                'background-size': 'cover',
                'background-repeat': 'no-repeat',
                'background-position': '50% 50%',
              }}
              alt=''
              id='header-background'
            />
          }
        >
          <Tween to={{ opacity: 1 }} duration={2} delay={1} />
          <Tween
            from={{ yPercent: 0 }}
            to={{
              yPercent: 30,
              // opacity: 0.5,
              scrollTrigger: {
                scrub: 0.5,
                markers: true,
              },
            }}
          />
        </Timeline>

        <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} delay={2} duration={3}>
          <h1
            style={{
              color: 'white',
              fontFamily: "'Pinyon Script','Promocyja', sans-serif",
              fontSize: '3em',
              'background-color': 'rgba(0, 0, 0, 0.5)',
            }}
          >
            Objevujte s námi chuť vína
          </h1>
        </Tween>
      </div>

      <div className='section'>
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            'background-color': 'blue',

            background: 'url(./imgs/3.png) no-repeat',
            'background-size': 'cover',
          }}
        ></div>
      </div>

      <Tween
        from={{ yPercent: 0 }}
        to={{
          yPercent: -30,
          // opacity: 0.5,
          scrollTrigger: {
            scrub: 0.5,
            // markers: true,
          },
        }}
      >
        <div className='section' style={{ 'z-index': '1' }}>
          {/* <div
            style={{
              width: '100%',
              height: '100%',
              'object-fit': 'cover',
              'background-color': 'blue',
              'z-index': 50,

              background: 'url(./imgs/vino2.jpg) no-repeat',
              'background-size': 'cover',
            }}
          > */}
          <img
            src='./imgs/vino2.jpg'
            style={{
              // position: 'relative',
              // top: 0,
              // left: 0,
              'min-width': '100%',
              'min-height': '100%',
            }}
          />
          {/* </div> */}
        </div>
      </Tween>
      <Reveal repeat threshold={0}>
        <Tween
          duration={3}
          from={{
            opacity: 0,
            // y: '-300px',
          }}
        >
          <h2
            style={{
              position: 'relative',
              left: 0,
              // transform: 'translate(0%, -20%) translate3d(0px, 0px, 0px)',
              bottom: '190px',
              width: '100%',
              'text-align': 'center',
              color: 'white',
              'z-index': '0',
            }}
          >
            Objevuji se na konci? Harry?
          </h2>
        </Tween>
      </Reveal>
    </ParallaxStyled>

    {/* <div class='row'> */}
    <Reveal repeat threshold={0}>
      <Tween
        from={{
          x: '-100px',
          opacity: '0',
          duration: '2',
          // repeat: 'true',
          // scrollTrigger: {
          //   trigger: '#white',
          //   // start: '-200px center',
          //   start: 'top center',
          //   end: 'bottom bottom',
          //   // end: '+=3000',
          //   // pin: true,
          //   scrub: 1,
          //   markers: true,
          // },
        }}
      >
        <div
          style={{
            width: '100vw',
            height: '100vh',
            background: 'url(./imgs/1.png) no-repeat',
            'background-size': 'cover',
          }}
        >
          <span style={{ position: 'absolute' }}>Tady je popis obrazku</span>
        </div>
      </Tween>
    </Reveal>

    <Reveal repeat threshold={0}>
      <Tween
        duration={2}
        from={{
          x: '100px',
          opacity: '0',
        }}
      >
        <div
          style={{
            width: '100vw',
            height: '100vh',
            background: 'url(./imgs/2.png) no-repeat',
            'background-size': 'cover',
          }}
        />
      </Tween>
    </Reveal>

    <Reveal repeat>
      <Tween from={{ opacity: 0 }} duration={2}>
        <h3>Objevuji se na konci? Harry?</h3>
      </Tween>
    </Reveal>
    {/* </Tween> */}

    {/* </div> */}

    {/* <div
      style={{
        width: '100%',
        height: '500px',
        position: 'relative',
        'background-color': 'black',
      }}
    ></div> */}
  </div>
)

export default Eshop
