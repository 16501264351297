export const data = [
  {
    imgPath: require('../../assets/imgs/WineBlackLogo.png'),
    // color: '#BFBFBF',
    text: `WineFinders, myšlenka této skupiny se zrodila, jak jinak než u degustace vína. Doslovný překlad \n
názvu je „vyhledávači vína“. Objevovat a zkoumat svět vína je pro nás nevšedním zážitkem, který \n
chceme dopřát každému, kdo alespoň jen lehce přivoněl k okraji sklenky. Čím více se o vinný svět \n
zajímáte, dochází Vám, že Česká vína jsou jen jednou kapkou v celé lahvi. Tento svět je opravdu
obrovský a my si dovolíme Vás v něm provést.`,
  },
  {
    imgPath: require('../../assets/imgs/Kaple_bzenec.jpg'),
    // color: '#FFE8A5',
    text: `WineFinders je větší skupina milovníků vína, která čítá nejednoho someliéra, degustátora či vinaře.
Ovšem tváří celého projektu jsem byl zvolen já - Lukáš Svobodník. Původem z Bzence, který je znám
jako vinařské město. Tudíž od mala mělo víno svůj kousek místa v mém srdci, který se časem rozrostl
v obrovskou vášeň. Tento vinný svět objevuji bezmála již 15 let, ať je to prací ve vinohradu či
sklepech, vzdělávání se na kurzech či veletrzích, nebo degustacích u nás či ve světě.`,
  },
  {
    imgPath: require('../../assets/imgs/Portret_2.jpg'),
    // color: '#D9D9D9',
    text: `Vše se vždy točí okolo těch úžasných bobulí. Naší myšlenkou je vyhledávat vína, která jsou svou chutí
nám srdci nejblíže a doprovázet je pochutinami, které můžeme najít ve svém okolí díky malým
regionálním výrobcům. Správným propojením těchto dvou odvětví vytvoříme to pravé „Genius Loci“
Vše co děláme, děláme srdcem. Jsme velmi rádi, že naše milovaná amatérská činnost se otočila do
profesionální sféry a my Vám tak můžeme nabídnout neopakovatelný zážitek.`,
  },
]
