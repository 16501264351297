export const Footer = () => {
  return (
    <div
      style={{
        position: 'absolute',
        botom: '0',
        marginTop: '120px',
        width: '100%',
        backgroundColor: 'black',
        textAlign: 'center',
        fontFamily: 'Belleza,sans-serif',
      }}
    >
      <br />
      <h2> WINEfinders 2023</h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <span> Wine.finderscz@gmail.com</span>
        <span style={{ margin: '0 10px 0 10px' }}> | </span>

        <span>+420 739 152 199</span>
      </div>
      <br />
    </div>
  );
};
