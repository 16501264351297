import { useEffect, useState } from 'react'

function ScrollFunction() {
  const [scrollPosition, setScrollPosition] = useState(0)
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const currentPosition = window.scrollY || document.documentElement.scrollTop
    setScrollPosition(currentPosition)
  }

  const changeStyling = () => {
    if (scrollPosition > 0) {
      document.getElementById('navbar').style.fontSize = '1em'
      document.getElementById('NavbarLogo').style.height = '60px'
      document.getElementById('NavbarLogoPhone').style.height = '60px'
    } else {
      document.getElementById('navbar').style.fontSize = '1.2em'
      document.getElementById('NavbarLogo').style.height = '100px'
      document.getElementById('NavbarLogoPhone').style.height = '100px'
    }
  }

  useEffect(() => {
    setTimeout(() => {
      changeStyling()
    }, 200)
  }, [scrollPosition])

  return <></>
}

export default ScrollFunction
