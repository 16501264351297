export const data = [
  {
    // imgPath: require('../../assets/imgs/Portret.jpg'),
    text: `<h5>Svobodník Lukáš</h5>
tel. <i>+420 739 152 199</i>
</br>
email. <i>Wine.finderscz@gmail.com</i>
</br>

<a href="https://www.instagram.com/wine.finders/">https://www.instagram.com/wine.finders/</a>`,
  },
  {
    imgPath: require('../../assets/imgs/QR.png'),
  },
]
