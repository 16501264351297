import styled from 'styled-components'

const StyledImages = styled.div`
  .wraper {
    overflow: hidden;
    margin: 0 auto;
    max-width: 90%;
  }

  .section {
    position: relative;
    margin-top: -50px;
    max-height: 80vh;
    overflow: hidden;
  }

  .section img:first-child {
    max-height: 100vh;
  }

  .nadpis {
    padding-top: 70px; /* Add space above the h1 */
    padding-bottom: 30px; /* Add space below the h1 */
  }

  h1 {
    text-align: center;
  }

  .section:nth-child(2) {
    margin-top: 20px;
  }

  .column img {
    display: block;
    margin: 0 auto;
  }

  .column img:hover {
    transition: transform 3s;
    transform: scale(1.2) !important;
  }

  @media (min-width: 1200px) {
    .section:nth-child(even) {
      left: 200px;
    }

    .section:nth-child(odd) {
      right: 200px;
    }
  }

  @media (max-width: 768px) {
    .section img {
      padding: 0px;
      width: 100%;
    }
  }
`
export default StyledImages
