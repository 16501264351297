import React from 'react'

import Page from '../../components/ImagesPage'
import { data } from './content'
import StyledImages from '../../components/StyleImages'

const Domu = () => {
  return (
    <StyledImages>
      <div>
        <Page data={data} />
      </div>
    </StyledImages>
  )
}

export default Domu
