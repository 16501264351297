import React from 'react'
import Router from './Routes'
import { RouterProvider } from 'react-router-dom'

// import { Footer } from './footer.js'
// import Layout from './pages/Layout.js'

function App() {
  return (
    <>
      <RouterProvider router={Router} />;{/* <Footer /> */}
    </>
  )
}

export default App
