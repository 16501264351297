import styled from 'styled-components'

const StyledFlowPage = styled.div`
  * {
    box-sizing: border-box;
  }

  .section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 200px;
  }

  .column {
    padding-left: 4rem;
  }

  .box {
    position: relative;
    bottom: -100px;
    width: 400px;
    padding: 30px;
    background-color: white;
    --c: black;
    --w: calc(50% - 50px);
    --b: 4px;
  }

  .nadpis {
    padding-top: 70px; /* Add space above the h1 */
    padding-bottom: 30px; /* Add space below the h1 */
  }

  h1 {
    text-align: center;
    margin: 0; /* Reset default margin */
  }

  .section img {
    max-height: 80vh;
    box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.2),
      6px 6px 20px 6px rgba(0, 0, 0, 0.19);
    overflow: hidden;
    padding: 50px;
  }

  @media (max-width: 768px) {
    .column {
      padding: 0;
    }
    .section img {
      padding: 0px;
      width: 100%;
    }

    .box {
      width: 100%;
    }
  }

  .section:nth-child(even) img:hover {
    transition: transform 3s;
    transform: scale(1) !important;
  }

  .section:nth-child(odd) img:hover {
    transition: transform 3s;
    transform: scale(1) !important;
  }

  .section p {
    margin-top: -200px;
  }
`
export default StyledFlowPage
