import React from 'react'
import { Tween, Timeline } from 'react-gsap'

import MapImage from './ImagesFlowSides'

const Domu = ({ data }) => (
  <div className="wraper">
    <Timeline>
      <div className="nadpis">
        <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} delay={1} duration={3}>
          <h1
            style={{
              color: 'black',
              fontFamily: "'Pinyon Script','Promocyja', sans-serif",
              fontSize: '4em',
            }}
          >
            Objevujte s námi svět vína
          </h1>
        </Tween>
      </div>

      {data.map((dat, index) => {
        return <MapImage key={index} {...dat} index={index} />
      })}
    </Timeline>
  </div>
)

export default Domu
