export const data = [
  {
    imgPath: require('../../assets/imgs/Lahve_vecirek.jpg'),
    // color: '#BF9E75',
    nadpis: 'Společenský večer',
    text: `Pořádáte soaré pro své obchodní partnery, či máte svůj nový pracovní team a potřebuje takzvaně
„Ice break“. Ať už se jedná o dýmkový klub, či sportovní team, vždy se nám podaří vykouzlit
neopakovatelný zážitkový večer, o kterém se budete bavit ještě hodně dlouho. Vytváříme nevšední
degustace vhodné právě pro tyto večery, objevte s námi špičková Česká vína či netradiční zahraniční
svět. Veškeré degustace vytvoříme speciálně pro Vás, abychom uspokojili Vaše chuťové pohárky.`,
  },
  {
    imgPath: require('../../assets/imgs/Vino_svatba.jpeg'),
    nadpis: 'Svatba',
    // color: '#F2AD94',
    text: `Víme, že svatba je pro Vás jeden z nejdůležitějších dnů v životě. Proto nechte na nás, abychom Vás a
Vaše hosty hýčkali tichými i šumivými víny. Navíc připravíme pro svatební hosty menší zábavu
v podobě slepé degustace či rychlou školou „sabrage“. Náš malý elegantní pult je ozdobou každé
svatby a po celou dobu se o Vás bude starat náš proškolený personál.`,
  },
]
