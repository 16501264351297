import { React, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const toggleRef = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      const toggle = toggleRef.current;
      const isClickInside = toggle.contains(event.target);
      if (!isClickInside) {
        const navbarNav = document.querySelector('#navbarNav');
        if (navbarNav.classList.contains('show')) {
          toggle.click();
        }
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <>
      <div
        className='position-sticky'
        style={{ backgroundColor: 'black', marginBottom: '2px' }}
      >
        <ul className='nav nav-fill navbar-nav w-100 navbar-collapse ms-auto'>
          <li className='nav-item d-none d-md-block'>
            <NavLink
              to='/'
              className={(isActive) =>
                'nav-link' + (!isActive ? ' unselected' : '')
              }
            >
              <div className='d-flex flex-column nav-link  p-0'>
                <img
                  style={{ height: '100px', transition: '0.4s' }}
                  src='/Wine_white_alpha.svg'
                  alt='Logo'
                  id='NavbarLogo'
                />
              </div>
            </NavLink>
          </li>
        </ul>
      </div>
      <nav
        className='navbar navbar-expand-md navbar-dark sticky-top'
        style={{
          fontFamily: 'Belleza,sans-serif',
          backgroundColor: 'black',
          fontSize: '1.2em',
          transition: '0.4s',
        }}
        id='navbar'
      >
        <div className='d-flex flex-grow-1 flex-column nav-link navbar-nav align-items-center d-md-none d-lg-none d-xl-none p-0'>
          <NavLink to='/' className='nav-link p-0'>
            <img
              style={{ height: '100px', transition: '0.4s' }}
              src='/Wine_white_alpha.svg'
              alt='Logo'
              id='NavbarLogoPhone'
            />
          </NavLink>
        </div>

        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarNav'
          aria-controls='navbarNav'
          aria-expanded='false'
          aria-label='Toggle navigation'
          style={{ position: 'absolute' }}
          ref={toggleRef}
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <div className='collapse navbar-collapse' id='navbarNav'>
          <ul className='nav  navbar-nav justify-content-evenly navbar-collapse ms-auto'>
            <li className='nav-item'>
              <NavLink
                to='/sluzby'
                className={(isActive) =>
                  'nav-link' + (!isActive ? ' unselected' : '')
                }
              >
                Služby pro Vás
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                to='/eshop'
                className={(isActive) =>
                  'nav-link' + (!isActive ? ' unselected' : '')
                }
              >
                E-Shop
              </NavLink>
            </li>

            <li className='nav-item'>
              <NavLink
                to='/pribeh'
                className={(isActive) =>
                  'nav-link' + (!isActive ? ' unselected' : '')
                }
              >
                Náš příběh
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                to='/kontakt'
                className={(isActive) =>
                  'nav-link' + (!isActive ? ' unselected' : '')
                }
              >
                {' '}
                Kontakt
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
