import React, { useEffect, useState } from 'react';
import { Reveal, Tween } from 'react-gsap';

const MapImage = ({ imgPath, nadpis, color, text, index }) => {
  const [, setScreenWidth] = useState(window.innerWidth);
  const isEven = index % 2 === 0;
  console.log(index);

  useEffect(() => {
    // Update the screenWidth state when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className='section'
      // style={{ border: '2px solid red' }}
      style={{ flexDirection: isEven ? 'row-reverse' : 'row' }}
    >
      <Reveal threshold={0}>
        {imgPath && (
          <Tween
            delay={1}
            duration={3}
            ease='power4'
            from={{
              filter: 'blur(8px)',
              x: isEven ? '-100%' : '100%',
              opacity: '0',
            }}
            to={{
              x: '0',
              filter: 'blur(0px)',
              opacity: '1',
            }}
          >
            <div className='column'>
              <img src={imgPath} alt={text} />
            </div>
          </Tween>
        )}

        {text && (
          <Tween
            // delay={!index ? 2 : 2 / index}
            delay={1}
            duration={3}
            ease='power4'
            from={{
              filter: 'blur(8px)',
              x: isEven ? '100%' : '-100%',
              opacity: '0',
            }}
            to={{
              x: '0',
              filter: 'blur(0px)',
              opacity: '1',
            }}
          >
            <div className='column'>
              <div className='box'>
                {nadpis && <h5>{nadpis}</h5>}
                <div dangerouslySetInnerHTML={{ __html: text }} />
                {/* {text} */}
              </div>
            </div>
          </Tween>
        )}
      </Reveal>
    </div>
  );
};

export default MapImage;
