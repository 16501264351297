import { Outlet } from 'react-router-dom'
import { Footer } from '../footer'
import Navbar from './Navbar'
import ScrollToTop from '../components/ScrollToTop'
import EnlargeNavbar from '../components/EnlargingNavbar'
function Layout() {
  return (
    <>
      <Navbar />
      <ScrollToTop />
      <EnlargeNavbar />
      <Outlet />
      <Footer />
    </>
  )
}
export default Layout
