import React from 'react'

import Page from '../../components/ImagesPage'
import { data } from './content'
import StyledFlowPage from '../../components/StyleImagesText'

const Kontakt = () => {
  return (
    <StyledFlowPage>
      <div>
        <Page data={data} />
      </div>
    </StyledFlowPage>
  )
}

export default Kontakt
